import React, { useRef, useState, useEffect } from 'react';
import { useUpdateEffect } from 'react-use';
import { useLocation, matchPath, useHistory } from 'react-router-dom';
import cn from 'classnames';

import appRoutes from 'appRoutes';
import Container from 'components/Container';
import Typography from 'components/Typography';
import { useHeaderBarContext } from 'components/HeaderBar/HeaderBarContext';
import Button from 'components/Button';
import IntegrationsModals, {
  useIntegrationsModals,
} from 'components/IntegrationsPanel/Integrations/components/IntegrationsModals';
import { useCurrentUser } from 'hooks/useSelector';
import UserPresenter from 'presenters/UserPresenter';
import useStyles from './useStyles';

interface RouteChangeNotificationProps {
  tabHeaderTitle?: boolean;
  headerBar: boolean;
  headerBarClasses: {
    header: string;
    title: string;
  };
  tool: any;
}

const RouteChangeNotification = ({
  tabHeaderTitle,
  headerBar,
  headerBarClasses,
  tool,
}: RouteChangeNotificationProps) => {
  const location = useLocation();
  const { pathname } = location;
  const alertRef = useRef<HTMLDivElement>(null);
  const [seed, setSeed] = useState(1);
  const [title, setTitle] = useState('');
  const { buttonsConfig, handleShow } = useHeaderBarContext();
  const history = useHistory();
  const currentUser = useCurrentUser();
  const isSysAdmin = currentUser != null ? UserPresenter.isSystemAdministrator(currentUser) : false;
  const { createResource, createConfiguration, integrationsModalsState, canEdit, toolId } = useIntegrationsModals();

  const classes = useStyles();

  useEffect(() => {
    if (headerBar) {
      setTimeout(() => {
        setTitle(document.title.replace(' - LearnPlatform', ''));
        setSeed(Math.random());
      }, 200);
    }
  }, [location]);

  useUpdateEffect(() => {
    setTimeout(() => {
      if (!tabHeaderTitle && !headerBar && !window.location.href.includes('#content') && alertRef.current) {
        alertRef.current.innerText = `Navigated to ${document.title}`;
      }
    }, 200);
  }, [location]);

  const displayTitle = () => {
    return (
      headerBar &&
      title !== 'LearnPlatform' &&
      !isActiveRoute(appRoutes.settingsFormTemplatesPath()) &&
      !isActiveRoute(appRoutes.settingsWorkflowsPath()) &&
      !isActiveRoute(appRoutes.settingsColumnsPath()) &&
      !isActiveRoute(appRoutes.settingsSmartLaunchPath())
    );
  };

  const showButtons = () => {
    // if we are not on the integrations tab return true
    // otherwise check if we can edit the tool
    if (!isActiveRoute(appRoutes.managementToolDetailsIntegrationsPath(toolId))) return true;
    if (canEdit) return true;

    return false;
  };

  const isActiveRoute = route => {
    return matchPath(pathname, {
      path: route,
      strict: true,
    });
  };

  const addEvidence = action => {
    if (isSysAdmin) {
      // if we are in the management path stay, otherwise go to the tool path.
      if (tool.companyId && location.pathname === appRoutes.managementToolDetailsEvidencePath(tool.id)) {
        history.push(appRoutes.managementToolDetailsEvidenceFormPath(tool.id));
      } else {
        history.push(appRoutes.toolEvidenceFormPath(tool.id));
      }
    } else {
      handleShow(action);
    }
  };

  const actionHandlers = {
    url: (action: string) => window.open(action, '_blank'),
    showModal: (action: string) => handleShow(action),
    addEvidence: (action: string) => addEvidence(action),
    createResource: () => createResource(),
    createConfiguration: () => createConfiguration(),
  };

  const handleButtonClick = (action: string, actionKey: string) => {
    actionHandlers[actionKey](action);
  };

  return (
    <>
      {!tabHeaderTitle && !headerBar && <div ref={alertRef} role="alert" className="lp-screen-reader-only" />}
      <IntegrationsModals {...integrationsModalsState} />
      {displayTitle() && (
        <Container className={headerBarClasses.header}>
          <Typography variant="h1" key={seed} className={headerBarClasses.title}>
            {title}
          </Typography>
          <div>
            {showButtons() &&
              buttonsConfig?.map(button => (
                <Button
                  key={button.label}
                  className={cn(classes[`${button.style}`])}
                  onClick={() => handleButtonClick(button.action, button.actionKey)}
                  data-node={button.id}
                  icon={button.icon}
                >
                  {button.label}
                </Button>
              ))}
          </div>
        </Container>
      )}
    </>
  );
};

export default RouteChangeNotification;
